.inputContainer {
  input {
    outline: none;
    border-radius: 10px;
    // border: 1px solid #e4e4e4;
    // color: #333333;
    // border: 1px solid #595959;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
