@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: "Manrope", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: white;
  color: var(--ono-primary-text);
  font-weight: 600;
  /* background-color: var(--ono-primary-background); */
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}
/* background color classs */
.bg-white {
  background-color: #ffffff;
}

.bg-yellow {
  background-color: var(--ono-yellow);
}
.bg-blue {
  background-color: var(--ono-blue);
}
.bg-primary {
  background-color: var(--ono-primary) !important;
}
.bg-light-primary {
  background: var(--ono-select-background);
}
.bg-primary-tint {
  background-color: #e7f8eb;
}
.bg-red-tint {
  background-color: #ffefef;
}
.bg-gray {
  background-color: #f9f9f9;
}
.bg-gray-bg {
  background-color: #eff3ef;
}
.bg-select {
  background-color: var(--ono-select-background);
}
.bg-light-red {
  background-color: #ffe7e7;
}
.bg-gray-dark {
  background-color: #f4f4f4;
}
.bg-red {
  background-color: #d43939;
}
/* //text color classes/ */
.color-primary {
  color: var(--ono-primary);
}
.color-blue {
  color: var(--ono-blue);
}
.bg-light-blue {
  background-color: #e7f1ff;
}

.color-red {
  color: var(--ono-red);
}
.color-yellow {
  color: var(--ono-yellow);
}
.color-orange {
  color: var(--ono-orange);
}
.color_red {
  color: var(--ono-red);
}
.color-text-primary {
  color: var(--ono-primary-text);
}
.color-sub-text {
  color: var(--ono-subtext);
}
.color-brown {
  color: #6a422e;
}
.bg-lightRed {
  background-color: #ffe5da;
}
.br-5 {
  border-radius: 5px;
}
.br-50 {
  border-radius: 50%;
}
.br-100 {
  border-radius: 100%;
}
.br-10 {
  border-radius: 10px;
  clip-path: inset(0 round 10px);
}
.border {
  border: 1px solid #dddddd;
}
.border-blue {
  border-color: var(--ono-blue);
}
.border-primary {
  border: 1px solid var(--ono-primary);
}
.border-gray {
  border: 1px solid var(--ono-gray);
}
.border-red {
  border: 1px solid var(--ono-red);
}
.color_white {
  color: #ffffff;
}
.border-dashed-blue {
  border: 1px dashed var(--ono-blue);
}
.border-b {
  border-bottom: 1px solid #dddddd;
}
.border-t {
  border-top: 1px solid #dddddd;
}
.border-r {
  border-right: 1px solid #dddddd;
}
.border-l {
  border-left: 1px solid #dddddd;
}
.color-inactive {
  color: var(--ono-tab-inactive);
}
.border-primary-b {
  border-bottom: 1px solid var(--ono-primary);
}
.active_border {
  border-bottom: 4px solid var(--ono-primary);
  border-radius: 10px 10px 0px 0px;
}
.outlet_padding,
.p-20 {
  padding: 20px;
}
.icon_bg {
  background-color: #f4f4f4;
}
.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}
/*hover class*/
.hv-primary {
  &:hover {
    background-color: var(--ono-select-background);
  }
}
.fs-12 {
  font-size: var(--body-small-text);
}
.fs-14 {
  font-size: var(--header5-text);
}
.bg-yellow {
  background-color: #fff536;
}
:root {
  --rgb-primary: 22, 161, 44;
  --rgb-border-primary: 228, 228, 228;
  --ono-primary: rgb(var(--rgb-primary), 1);
  --ono-yellow: #fff536;
  --ono-orange: #ff7236;
  --ono-red: #d43939;
  --ono-blue: #0066ff;
  --ono-gray: #d3d3d3;
  --ono-primary-background: #eff3ef;
  --ono-select-background: #d7f3dd;
  --ono-primary-text: #171717;
  --ono-white: #ffffff;
  --ono-subtext: #595959;
  --ono-border-primary: rgb(var(--rgb-border-primary), 1);
  --main-heading: 24px;
  --header2-text: 20px;
  --header3-text: 16px;
  --btn-text: 15px;
  --header5-text: 14px;
  --body-text: 13px;
  --body-small-text: 12px;
  --ono-tab-inactive: #838383;
}
.text_overflow {
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 1s linear infinite;
}
/* new v2 */
.w-50 {
  width: 50%;
}
.screen_bg {
  background-color: #eff3ef;
}
.w-25 {
  width: 25%;
}
.space_18 {
  margin-top: 18px;
}
.w-20 {
  width: 20%;
}
.w-15 {
  width: 15%;
}
.w-30 {
  width: 30%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.gradient_bg {
  background: linear-gradient(90deg, #16a02c 0%, #138024 71.23%, #16a02c 100%);
}
.tab_gradient_bg {
  background: linear-gradient(
    82.64deg,
    #16a02c -31.11%,
    #0d5818 44.23%,
    #16a02c 98.52%
  );
}
.card_space_18 {
  padding: 16px;
}
.br-30 {
  border-radius: 30px;
}
.py_10 {
  padding: 10px 16px;
}
.mt_8 {
  margin-top: 8px;
}
.br-0 {
  border-radius: 0px;
}
.card_bg_head {
  padding: 8px 16px;
}
.border_b_gradient {
  border-bottom: 1px solid;
  border-image-source: linear-gradient(
    90deg,
    #ffffff 0%,
    #dddddd 50.84%,
    #ffffff 100%
  );
  border-image-slice: 1;
}
.border_r_gradient {
  border-right: 1px solid;
  border-image-source: linear-gradient(
    180deg,
    #ffffff 0%,
    #dddddd 50.84%,
    #ffffff 100%
  );
  border-image-slice: 1;
}
.layout_bg {
  background-color: #eff3ef;
}

.w-100 {
  width: 100%;
}
.click_card_height {
  height: 520px;
}

.h-100 {
  height: 100%;
}
.CircularProgressbar-text {
  dominant-baseline: middle;
  text-anchor: middle;
}
.activeScroll {
  overflow-x: auto;
  position: relative;
  /* // scrollbar-color: #16a02c #16a02c;
  // scrollbar-width: thin; */
  -ms-overflow-style: none;
  /* padding-bottom: 10px; */
  white-space: nowrap;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: gray;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb:horizontal {
  background: var(--ono-primary);
  border-radius: 30px;
}
